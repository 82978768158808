import { Table, TableHead, TableRow } from '@mui/material'
import { usePartnerFetchInstrumentProofreadEventsApi } from 'api/partner/instruments/proofread_events'
import Modal, { ModalSet } from 'components/shared/modal'
import { ExTableBody, ExTableCell } from 'components/shared/table'
import ThemedButton from 'components/shared/themed_button'
import { ProofreadEvent } from 'entities/proofread_event'
import React from 'react'
import { useEffectSkipFirst } from 'utils/hooks'
import { ProofreadEventViewModel } from 'view_models/proofread_event'

type EventHistoryModalProps = {
  modalSet: ModalSet<ProofreadEvent>
}
export const EventHistoryModal: React.FC<EventHistoryModalProps> = (props) => {
  const indexApi = usePartnerFetchInstrumentProofreadEventsApi(
    props.modalSet.item.instrumentId,
  )

  useEffectSkipFirst(() => {
    indexApi.execute()
  }, [props.modalSet.item.id])

  return (
    <Modal
      modalId="event-history-modal"
      modalState={props.modalSet.modalState}
      title="過去の校正"
      size="lg"
      subTitle={props.modalSet.item.instrument?.proofreadInfo?.instrument?.instrumentBasicInfo?.name || ''}
      footer={
        <>
          <ThemedButton onClick={props.modalSet.closeModal}>閉じる</ThemedButton>
        </>
      }
    >
      <Table>
        <TableHead>
          <TableRow>
            <ExTableCell>校正月</ExTableCell>
            <ExTableCell>校正価格</ExTableCell>
            <ExTableCell>ステータス</ExTableCell>
            <ExTableCell>結果</ExTableCell>
          </TableRow>
        </TableHead>
        <ExTableBody loading={indexApi.loading}>
          {indexApi.response.proofreadEvents
            .filter((e) => e.proofreadPlanId !== props.modalSet.item.proofreadPlanId)
            .map((event) => {
              const eventVm = new ProofreadEventViewModel(event)
              return (
                <TableRow key={event.id}>
                  <ExTableCell>{eventVm.getScheduledDate()}</ExTableCell>
                  <ExTableCell>{eventVm.getPrice()}</ExTableCell>
                  <ExTableCell>{eventVm.getStatusLabel()}</ExTableCell>
                  <ExTableCell>{eventVm.getResultLabel()}</ExTableCell>
                </TableRow>
              )
            })}
        </ExTableBody>
      </Table>
    </Modal>
  )
}

export default EventHistoryModal
