import { BaseEntity } from 'entities'
import { AttachmentAttributes } from 'entities/attachment_attributes'
import { DefectReport } from 'entities/defect_report'
import { ExAttribute } from 'entities/ex_attribute'
import { Instrument } from 'entities/instrument'
import { ProofreadApprover } from 'entities/proofread_approver'
import { ProofreadEventAttachment } from 'entities/proofread_event_attachments'
import { ProofreadPlan } from 'entities/proofread_plan'
import { ProofreadSchedule } from 'entities/proofread_schedule'
import { RepairEvent } from 'entities/repair_event'
import { Reservation } from 'entities/reservation'
import { Supplier } from 'entities/supplier'
import { SearchQuery } from 'rac'

// NOTE: 詳細は entities/instrument.ts のコメントを参照
const orginalKeys = [
    'id', 'proofreadPlanId', 'userId', 'instrumentId', 'suppliableId', 'suppliableType', 'proofreadScheduleId', 'reservationId',
    'price', 'scheduledDate', 'taxRate', 'noteOnPlan', 'noteOnResult', 'noteOnDealerResult', 'aasmState', 'repairState', 'result',
  ] as const

type OriginalKeyTypeMap = BaseEntity & {
  proofreadPlanId?: number
  userId?: number
  instrumentId?: number
  suppliableId?: number
  suppliableType?: string
  proofreadScheduleId?: number
  reservationId?: number

  price?: number
  scheduledDate?: string
  taxRate?: number
  noteOnPlan?: string
  noteOnResult?: string
  noteOnDealerResult?: string
  aasmState?: ProofreadEventState
  repairState?: ProofreadEventRepairState
  result?: 'ok' | 'ng' | 'canceled'
}

// モデルそのものの属性
type OriginalProperties = {
  [K in typeof orginalKeys[number]]?: OriginalKeyTypeMap[K]
}

/**
 * 校正計画イベント
 */
export type ProofreadEvent = OriginalProperties & {
  // リレーションやロジックのために付随する属性
  mayPlanned?: boolean
  tableExAttrs?: ExAttribute[]
  proofreadPlan?: ProofreadPlan
  userName?: string
  instrument?: Instrument
  supplier?: Supplier
  proofreadSchedule?: ProofreadSchedule
  lastEvent?: ProofreadEvent
  approvers?: ProofreadApprover[]
  attachments?: ProofreadEventAttachment[]
  exAttributes?: ExAttribute[]
  reservation?: Reservation
  defectReport?: DefectReport
  repairEvent?: RepairEvent
}

const formKeys = ['approverIds', 'attachmentsAttributes', 'requireSupplier', 'instrumentIds', 'eventIds', 'startDate', 'endDate', 'approveNoRepair', 'approveUneditable', 'proofreadInfoAttributes', 'proofreadEventIds'] as const

type FormKeyTypeMap = {
  approverIds?: number[]
  attachmentsAttributes?: AttachmentAttributes[]
  requireSupplier?: boolean
  instrumentIds?: number[]
  eventIds?: number[]
  startDate?: string
  endDate?: string
  approveNoRepair?: boolean
  approveUneditable?: boolean
  proofreadInfoAttributes?: { cycle?: number; cycleUnit?: string }
  proofreadEventIds?: number[]
}

type FormProperties = {
  [K in typeof formKeys[number]]?: FormKeyTypeMap[K];
}

/**
 * フォームで用いる属性。定義していない属性は型エラーで弾く
 */
export type ProofreadEventForm = OriginalProperties & FormProperties

/**
 * 送信するフォームのキー。ここにないものは送信されない
 */
export const ProofreadEventFormKeys: (keyof ProofreadEventForm)[] = [...orginalKeys, ...formKeys]

export type ProofreadEventState =
  | 'no_plan'
  | 'planned'
  | 'approved_plan'
  | 'fix_date'
  | 'waiting_date'
  | 'attached_result'
  | 'fix_result'
  | 'approved_result'
  | 'completed'

export type ProofreadEventRepairState = 'unnecessary' | 'reported' | 'estimated' | 'unrepaired' | 'repaired' | 'unrepairable'

export type ProofreadEventBulkPatchForm = Pick<ProofreadEventForm, 'scheduledDate' | 'price' | 'noteOnPlan' | 'aasmState' | 'userId'> & {
  proofreadEventIds: number[]
}

export type ProofreadEventSearchForm = {
  q: {
    proofreadScheduleIdNull?: boolean
    aasmStateIn?: ProofreadEventState[]
    proofreadInfoInstrumentMakerIn?: string[]
    proofreadInfoInstrumentModelNumberIn?: string[]
    proofreadInfoInstrumentManageNumberIn?: string[]
    proofreadInfoInstrumentInstrumentCategoryIdIn?: string[]
  }
  searchText?: string
}

export const getProofreadEventSearchQuery = (searchText?: string): SearchQuery => ({
  suffix: 'Cont',
  searchText: searchText || '',
  attrs: [
    'point',
    'content',
    [
      'instrument',
      {
        attrs: ['name'],
      },
    ],
  ],
})

export const proofreadEventStateTexts: { [P in ProofreadEventState]: string } = {
  no_plan: '計画未入力',
  planned: '計画未承認',
  approved_plan: '日程未調整',
  waiting_date: '日程未承認',
  fix_date: '未実施',
  attached_result: '結果未入力',
  fix_result: '結果未承認',
  approved_result: '完了',
  completed: '完了',
}

export const supplierProofreadEventStateTexts: { [P in ProofreadEventState]: string } = {
  no_plan: '計画未入力',
  planned: '計画未承認',
  approved_plan: '日程未調整',
  waiting_date: '日程未承認',
  fix_date: '未実施',
  attached_result: '結果未入力',
  fix_result: '結果未承認',
  approved_result: '完了',
  completed: '完了',
}

/** 
 * ラボアカウントから見た、校正計画のステップの最小要素 
 *  ラオの校正計画は「承認の有無・作成者・共有機器を含むか」など複数の要素が絡むため
*/
export const MYPAGE_PROOFREAD_MINIMUM_STEPS: ProofreadEventState[] =
 ['no_plan', 'attached_result', 'approved_result']

/** 代理店アカウントから見た、ラボが開始した校正計画のステップ */
export const PARTNER_LAB_CREATED_PROOFREAD_STEPS: ProofreadEventState[] =
  ['approved_plan', 'waiting_date', 'fix_date', 'completed']

/** 代理店アカウントから見た、代理店が開始したリプルア顧客向け校正計画のステップ */
export const PARTNER_SUPPLIER_CREATED_LAB_PROOFREAD_STEPS: ProofreadEventState[] =
  ['no_plan', 'approved_plan', 'waiting_date', 'fix_date', 'completed']

/** 代理店アカウントから見た、代理店が開始した外部顧客向け校正計画のステップ */
export const PARTNER_SUPPLIER_CREATED_OUTSIDE_LAB_PROOFREAD_STEPS: ProofreadEventState[] =
  ['no_plan', 'attached_result', 'approved_result']
